/* eslint-disable prettier/prettier */
export const IAMPORT_KEY = window.env.IAMPORT_KEY;
export const CHANNEL_TALK_ID = window.env.CHANEL_TALK_ID;
export const HSCAN_FRONT_URL = window.env.HSCAN_FRONT_URL;
export const HSCAN_BACKEND_URL = window.env.HSCAN_BACKEND_URL;
export const HSCAN_BACKEND_API_URL = `${HSCAN_BACKEND_URL}/hscan`;
export const HSCAN_BACKEND_DEMOGRAPHICS_URL = `${HSCAN_BACKEND_URL}/demographics`;
export const CERT_BACKEND_URL = window.env.CERT_BACKEND_URL;
export const HSCAN_UPLOADER_URL = window.env.HSCAN_UPLOADER_URL;
export const HPACS_URL = window.env.HPACS_URL;
export const SHARE_CODE_URL = window.env.SHARE_CODE_URL;

export const deliveryFee = window.env.DELIVERY_FEE;

export const HGATE_INCLUDED = window.env.HGATE_INCLUDED;

export const FOOTER_DISABLED = window.env.FOOTER_DISABLED;

export const USE_THETRARIS_LOGO = window.env.USE_THETRARIS_LOGO;
export const HOSPITAL_NAME = window.env.HOSPITAL_NAME;
export const BRAND_NAME = window.env.BRAND_NAME;

declare global {
  interface Window {
    env: {
      IAMPORT_KEY: string;
      CHANEL_TALK_ID: string;
      HSCAN_FRONT_URL: string;
      HSCAN_BACKEND_URL: string;
      CERT_BACKEND_URL: string;
      HSCAN_UPLOADER_URL: string;
      HPACS_URL: string;
      SHARE_CODE_URL: string;
      DELIVERY_FEE: number;
      HGATE_INCLUDED: boolean;
      FOOTER_DISABLED: boolean;
      USE_THETRARIS_LOGO: boolean;
      HOSPITAL_NAME: string;
      BRAND_NAME: string;
    };
  }
}
