import { createContext } from 'react';

export interface Storage {
  getItem: (key: string) => Promise<string | null>;
  setItem: (key: string, item: string) => void;
  removeItem: (key: string) => void;
  clear: () => void;
}

export enum StorageKey {
  USER = 'USER',
  REMEMBER_USERNAME = 'REMEMBER_USERNAME',
}

class InMemoryStorage implements Storage {
  storage: { [key: string]: string };

  constructor() {
    this.storage = {};
  }

  async getItem(key: string) {
    return this.storage[key] ?? null;
  }

  setItem(key: string, item: string) {
    this.storage[key] = item;
  }

  removeItem(key: string) {
    delete this.storage[key];
  }

  clear() {
    this.storage = {};
  }
}

export const StorageContext = createContext<Storage>(new InMemoryStorage());
