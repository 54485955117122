import axios from 'axios';
import i18n from 'i18next';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom';
import { initReactI18next, useTranslation } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from 'styled-components';

import '../../shared/api/config';
import { Locale } from '../../shared/api/hscan/account';
import { Person } from '../../shared/api/hscan/demographics';
import { UserProvider } from '../../shared/context/UserContext';
// FIXME: move theme to shared
import { theme } from '../../shared/theme';
import {
  englishKey,
  englishWebTranslation,
  indonesianKey,
  indonesianWebTranslation,
  koreanKey,
  koreanWebTranslation,
  spanishKey,
  spanishWebTranslation,
} from '../../shared/translations';

import { LOCAL_STORAGE_LOCALE_KEY } from './common/components/LanguageSelector';
import { useWindowSize, WindowSize } from './hooks/windowSizeHook';
import './index.css';
import { kcContext } from './keycloak-theme/login/kcContext';
import reportWebVitals from './reportWebVitals';
import { onChangeLocale } from './utils/onChangeLocale';

const App = lazy(() => import('./App'));
const KcApp = lazy(() => import('./keycloak-theme/login/KcApp'));

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      [koreanKey]: koreanWebTranslation,
      [englishKey]: englishWebTranslation,
      [spanishKey]: spanishWebTranslation,
      [indonesianKey]: indonesianWebTranslation,
    },
    lng: koreanKey,
    fallbackLng: [englishKey, koreanKey],
    interpolation: {
      escapeValue: false,
    },
    compatibilityJSON: 'v3',
  });

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (retryCount, error) => {
        const shouldRetry = retryCount < 3;
        if (!axios.isAxiosError(error)) {
          return shouldRetry;
        }
        if (error.response?.status === undefined) {
          return shouldRetry;
        }
        // 5xx 에러인 경우 횟수만큼 retry 함
        // 4xx 에러인 경우 retry 하지 않음
        return error.response.status >= 500 && shouldRetry;
      },
    },
  },
});

const Root = () => {
  const windowSize = useWindowSize();
  const isMobile = windowSize === WindowSize.MOBILE;

  // set locale initially
  useEffect(() => {
    const localeInLocalStorage = localStorage.getItem(LOCAL_STORAGE_LOCALE_KEY);
    const localeInKeycloak = kcContext?.locale?.currentLanguageTag;
    let locale = localeInLocalStorage ?? localeInKeycloak ?? navigator.language;
    switch (locale) {
      case 'ko':
      case 'ko-KR':
        locale = 'ko';
        break;
      case 'es':
        locale = 'es';
        break;
      case 'id':
        locale = 'id';
        break;
      case 'en':
      case 'en-US':
      default:
        locale = 'en';
        break;
    }
    onChangeLocale(locale as Locale);
  }, []);

  const { i18n: i18nObject } = useTranslation();
  const currentLanguageInI18nObject = i18nObject.language;

  // update locale by user changed
  const [user, setUser] = useState<Person | undefined>(undefined);
  useEffect(() => {
    if (user && user.locale && user.locale !== currentLanguageInI18nObject) {
      const localeInLocalStorage = localStorage.getItem(
        LOCAL_STORAGE_LOCALE_KEY,
      ) as Locale;
      onChangeLocale(localeInLocalStorage ?? user.locale);
    }
    // user 가 변경됐고 user 와 i18nObject 가 다를 때만 작동하도록 함
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // update locale by keycloak changed (might be not called)
  const localeInKeycloak = kcContext?.locale?.currentLanguageTag as Locale;
  useEffect(() => {
    const localeInLocalStorage = localStorage.getItem(
      LOCAL_STORAGE_LOCALE_KEY,
    ) as Locale;
    if (localeInKeycloak !== currentLanguageInI18nObject) {
      onChangeLocale(localeInLocalStorage ?? localeInKeycloak);
    }
  }, [currentLanguageInI18nObject, localeInKeycloak]);

  return (
    <ThemeProvider theme={{ ...theme, isMobile }}>
      <QueryClientProvider client={client}>
        <DndProvider backend={HTML5Backend}>
          <UserProvider user={user} setUser={setUser}>
            <Suspense>
              {kcContext !== undefined ? (
                <KcApp kcContext={kcContext} />
              ) : (
                <App />
              )}
            </Suspense>
          </UserProvider>
        </DndProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
