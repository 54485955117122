import { PropsWithChildren, createContext } from 'react';

import { Person } from '../api/hscan/demographics';

export const UserContext = createContext<Person | undefined>(undefined);
export const SetUserContext = createContext<(user: Person | undefined) => void>(
  () => {
    return;
  },
);

interface UserProviderProps {
  user: Person | undefined;
  setUser(p: Person | undefined): void;
}
export const UserProvider = ({
  user,
  setUser,
  children,
}: UserProviderProps & PropsWithChildren) => {
  return (
    <UserContext.Provider value={user}>
      <SetUserContext.Provider value={setUser}>
        {children}
      </SetUserContext.Provider>
    </UserContext.Provider>
  );
};
