import React from 'react';
import styled from 'styled-components';

export const ColoredIconElement = styled.span<{
  $margin?: string;
  $width?: string;
  $height?: string;
  $color: string;
  $imageWidth?: string;
  $imageUrl: string;
}>`
  position: relative;
  display: inline-block;
  flex-shrink: 0;
  width: ${p => p.$width ?? p.$height ?? 'min-content'};
  height: ${p => p.$height ?? p.$width ?? 'min-content'};
  margin: ${p => p.$margin ?? '0px'};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${p => p.$color};
    mask-type: alpha;
    -webkit-mask-image: url(${p => p.$imageUrl});
    -webkit-mask-size: ${p => p.$imageWidth ?? '100%'};
    -webkit-mask-position: center;
    -webkit-mask-repeat: no-repeat;
  }
`;

interface ColoredIconProps {
  className?: string;
  margin?: string;
  width?: string;
  height?: string;
  color: string;
  imageWidth?: string;
  imageUrl: string;
}
export const ColoredIcon = ({
  className,
  margin,
  width,
  height,
  color,
  imageWidth,
  imageUrl,
}: ColoredIconProps) => (
  <ColoredIconElement
    className={className}
    $margin={margin}
    $width={width}
    $height={height}
    $color={color}
    $imageWidth={imageWidth}
    $imageUrl={imageUrl}
  />
);

const IconButtonElement = styled(ColoredIconElement)<{
  $disabled?: boolean;
}>`
  cursor: ${p => (p.$disabled ? 'default' : 'pointer')};
`;

interface IconButtonProps {
  margin?: string;
  width?: string;
  height?: string;
  color: string;
  imageWidth?: string;
  imageUrl: string;
  disabled?: boolean;
  onClick?(): void;
}
export const IconButton = ({
  margin,
  width,
  height,
  color,
  imageWidth,
  imageUrl,
  disabled,
  onClick,
}: IconButtonProps) => (
  <IconButtonElement
    $margin={margin}
    $width={width}
    $height={height}
    $color={color}
    $imageWidth={imageWidth}
    $imageUrl={imageUrl}
    $disabled={disabled}
    onClick={onClick}
  />
);
