import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import { LOGIN_IFRAME } from '../../../shared/api/oauth/oauth';
import { HSCAN_FRONT_URL } from '../../../shared/env';
import { getSignInUrl } from '../../../shared/utils/oauth';

export const REDIRECT_PATH_BEFORE_2FA = 'redirect-path-before-2fa';
const LOGIN_SUCCESS = 'oauth-login-success';
const TRY_SILENT_LOGIN = 'oauth-try-slient-login';
const REQUIRE_TWO_FACTOR_AUTHENTICATION =
  'oauth-require-two-factor-authentication';

// set session header if exists
axios.interceptors.response.use(
  response => response,
  // try silent login with 401
  error => {
    const { response } = error;
    if (response.status && response.status === 401) {
      if (response?.data?.errorCode === '4011') {
        window.postMessage(
          { type: REQUIRE_TWO_FACTOR_AUTHENTICATION },
          HSCAN_FRONT_URL,
        );
      } else {
        window.postMessage({ type: TRY_SILENT_LOGIN }, HSCAN_FRONT_URL);
      }
    }
    return Promise.reject(error);
  },
);

interface OAuthLoginSuccessMessage {
  type: typeof LOGIN_SUCCESS;
}
interface OAuthTrySilentLoginMessage {
  type: typeof TRY_SILENT_LOGIN;
}
interface OAuthRequireTwoFactorAuthenticationMessage {
  type: typeof REQUIRE_TWO_FACTOR_AUTHENTICATION;
}

const isLoginSuccessMessage = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  message: any,
): message is OAuthLoginSuccessMessage => {
  const type = (message as OAuthLoginSuccessMessage).type;
  return type && type === LOGIN_SUCCESS;
};
const isTrySilentLoginMessage = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  message: any,
): message is OAuthTrySilentLoginMessage => {
  const type = (message as OAuthTrySilentLoginMessage).type;
  return type && type === TRY_SILENT_LOGIN;
};
const isRequireTwoFactorAuthenticationMessage = (
  message: any,
): message is OAuthRequireTwoFactorAuthenticationMessage => {
  const type = (message as OAuthRequireTwoFactorAuthenticationMessage).type;
  return type && type === REQUIRE_TWO_FACTOR_AUTHENTICATION;
};

export const postLoginSuccessMessage = () => {
  if (isSilentLoginIframe()) {
    window.opener.postMessage(
      {
        type: LOGIN_SUCCESS,
      },
      HSCAN_FRONT_URL,
    );
  }
};

export const useSilentLogin = () => {
  const [onSilentLogin, setSilentLogin] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (onSilentLogin) {
      // open login page in iframe and try silent login
      window.open(
        getSignInUrl({ prompt: 'none', redirect: '/oauth-silent-callback' }),
        LOGIN_IFRAME,
      );
    } else {
      // cleanup iframe
      window.open('about:blank', LOGIN_IFRAME);
    }
  }, [onSilentLogin]);

  const listener = useCallback(
    (event: MessageEvent) => {
      if (event.origin !== HSCAN_FRONT_URL) {
        return;
      }
      if (isTrySilentLoginMessage(event.data)) {
        setSilentLogin(true);
      }
      if (isLoginSuccessMessage(event.data)) {
        queryClient.invalidateQueries('autoSignIn');
        setSilentLogin(false);
      }
      if (isRequireTwoFactorAuthenticationMessage(event.data)) {
        if (location.pathname !== '/two-factor-login') {
          // FIXME: useNavigator 를 써야하지만, useSilentLogin 이 Router 밖에 있어서 못 씀
          localStorage.setItem(
            REDIRECT_PATH_BEFORE_2FA,
            `${location.pathname}${location.search}`,
          );
          location.href = '/two-factor-login';
        }
      }
    },
    [setSilentLogin, queryClient],
  );

  useEffect(() => {
    window.addEventListener('message', listener);
    return () => {
      window.removeEventListener('message', listener);
    };
  }, [listener]);

  return onSilentLogin;
};

/**
 * silent login 이 실패한 후 호출되는 함수
 */
export const redirectToLoginPage = (redirect?: string) => {
  // 이 함수가 호출됐다는 것은 iframe 으로 로그인 페이지에 가보았고 실패했다는 뜻이다
  // 호출될 때 browser history stack 에는 [..., 이전 페이지, 새로 방문한 페이지, iframe 시도 흔적] 이 있다
  // 이것이 [..., 이전 페이지, 로그인 페이지] 가 되어야 로그인 페이지에서 뒤로 가기를 눌렀을 때 이전 페이지가 보이게 된다
  // 따라서 silent login 시도 history 와 현재 실패한 로그인 페이지 history 를 제거하고자 한다
  history.back(); // silent login 시도 history 제거
  location.replace(getSignInUrl({ redirect })); // 현재 실패한 로그인 페이지 history 를 로그인 URL로 replace
};

export const isSilentLoginIframe = () => {
  return !!window.opener;
};
