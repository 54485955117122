import axios from 'axios';

import { HSCAN_BACKEND_API_URL } from '../../env';

import { Gender, Locale } from './account';

// TODO: gender, birthDate enum이나 Date type 이어야 할 것 같음

export interface Person {
  userId?: string;
  username: string;
  name: string;
  gender: Gender; // F M
  email: string;
  telecom: string;
  /**
   * yyyy-MM-dd
   */
  birthDate: string;
  identified: false; // 사용자의 본인인증 여부
  locale?: Locale; // 사용자의 언어 설정 정보
}

export const requestUserInfo = () => {
  return axios.get<Person>(`${HSCAN_BACKEND_API_URL}/userinfo`);
};
