import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const LayoutBackground = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background-color: #f8f9fd;

  ${p =>
    p.theme.isMobile &&
    `
    background-color: #f5f5f5;
    `}
`;

export const LayoutHeaderContainer = styled.header<{ $height: string }>`
  position: relative;
  width: 100%;
  height: ${p => p.$height};
  background-color: ${p => '#00197e' || p.theme.colors.primary};
`;

export const LayoutHeaderContent = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 22px 20px 28px;
  margin: 0 auto; /* center */
  max-width: ${'1120px'};
  height: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  column-gap: 12px;
  color: ${p => p.theme.colors.white};
`;

export const LayoutHeaderContentLeft = styled.div``;

export const LayoutHeaderContentRight = styled.div<{ $gap?: string }>`
  display: flex;
  align-items: center;
  gap: ${p => p.$gap ?? 'unset'};
`;

export const LayoutHeaderLink = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${p => p.theme.colors.white};
  cursor: pointer;
  word-break: keep-all;
`;

export const UserInfo = styled.span`
  font-size: 16px;
  color: ${p => p.theme.colors.white};
`;

export const LayoutHeaderButton = styled.button`
  margin: 0;
  width: 100px;
  height: 40px;
  box-sizing: border-box;
  padding: 4px;
  border: none;
  border-radius: 20px;
  font-size: 13px;
  font-weight: 500;
  background-color: ${p => '#0051ff' || p.theme.colors.textColorInPrimary};
  color: ${p => p.theme.colors.white};
  cursor: pointer;
`;

export const LayoutFooter = styled.footer``;

export const LayoutFooterAside = styled.aside`
  background: ${p => p.theme.colors.white};

  ${p =>
    p.theme.isMobile &&
    `
    background: #f5f5f5;
    `}
`;

export const LayoutFooterMenu = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 35px;

  ${p =>
    p.theme.isMobile &&
    `
    padding-top: 24px;
    padding-bottom: 10px;
    `}
`;
export const LayoutFooterMenuSection = styled.section<{ $maxWidth?: number }>`
  flex: 1;
  ${p => (p.$maxWidth !== undefined ? `max-width: ${p.$maxWidth}px` : '')}
`;
export const LayoutFooterMenuSection2 = styled.section`
  flex: 1;
  display: contents;

  ${p =>
    p.theme.isMobile &&
    `
    display: block;
    `}
`;
export const LayoutFooterMenuSectionHidden = styled.div`
  display: contents;

  ${p =>
    p.theme.isMobile &&
    `
    display: none;
    `}
`;
export const LayoutFooterMenuHeading = styled.div`
  font-size: 16px;
  font-weight: 500;
  padding: 8px 0;
  color: ${p => '#1c1c1d'};

  ${p =>
    p.theme.isMobile &&
    `
    font-size: 12px;
    `}
`;
export const LayoutFooterMenuContent = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 14px;

  ${p =>
    p.theme.isMobile &&
    `
    font-size: 12px;
    `}
`;
export const LayoutFooterMenuContentItem = styled.li`
  margin: 0;
  padding: 8px 0;
`;
export const LayoutFooterMenuText = styled.a`
  color: ${p => '#7a7a7e'};
`;
export const LayoutFooterMenuLink = styled.a`
  color: ${p => '#7a7a7e'};
  text-decoration: none;
`;
export const LayoutFooterBoldMenuLink = styled(LayoutFooterMenuLink)`
  font-weight: 700;
`;
export const LayoutFooterMenuInternalLink = styled(Link)`
  color: ${p => '#7a7a7e'};
  text-decoration: none;
`;

export const LayoutFooterMenuSnsIcons = styled.div`
  display: flex;
  gap: 28px;
  line-height: 0;

  ${p =>
    p.theme.isMobile &&
    `
    gap: 16px;
    `}
`;
export const SnsIcon = styled.img``;

export const LayoutFooterCoperation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
`;

export const CompanyName = styled.span`
  font-size: 24px;
  font-weight: bold;
  color: ${p => '#9a9a9a'};

  ${p =>
    p.theme.isMobile &&
    `
    font-size: 15px;
    `}
`;
export const Copyright = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: ${p => '#c2c2c2'};

  ${p =>
    p.theme.isMobile &&
    `
    font-size: 8px;
    `}
`;

export const LayoutFooterContact = styled.address`
  height: 60px;
  font-size: 12px;
  line-height: 1;
  font-style: normal;
  background: ${p =>
    'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #5a6473;'};
  color: white;

  // shrink 일 때 글자 크기 줄임
  @media (max-width: 1129px) {
    font-size: 8px;
  }
`;

export const LayoutFooterContent = styled.div`
  margin: 0 auto; /* center */
  max-width: 1120px;
  padding: 0px 10px;

  ${LayoutFooterContact} & {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    box-sizing: border-box;
    padding: 10px;
    word-break: keep-all;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 4px;
  }
`;

export const AlertNoti = styled.span`
  position: absolute;
  right: 2px;
  top: 0;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #21d899;
  z-index: 1;
`;
