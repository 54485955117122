import { ResourceLanguage } from 'i18next';

import englishJson from './english.json';
import indonesianJson from './indonesian.json';
import koreanJson from './korean.json';
import spanishJson from './spanish.json';

/** @deprecated */
export const koreanTranslation: ResourceLanguage = {
  translation: {},
};

export const koreanWebTranslation: ResourceLanguage = {
  translation: koreanJson,
};

export const koreanKey = 'ko';

export const englishWebTranslation: ResourceLanguage = {
  translation: englishJson,
};

export const englishKey = 'en';

export const spanishWebTranslation: ResourceLanguage = {
  translation: spanishJson,
};

export const spanishKey = 'es';

export const indonesianWebTranslation: ResourceLanguage = {
  translation: indonesianJson,
};

export const indonesianKey = 'id';
