import { DefaultTheme } from 'styled-components/native';

export const theme: DefaultTheme = {
  isMobile: false,
  colors: {
    primary: '#254bb1',
    primaryVariant: '#426cdd',
    primaryVariant2: '#5f84e8',
    primaryVariant3: '#6a8ceb',
    primaryVariant4: '#4375ff',
    primaryVariant5: '#13307e',
    secondary: '#f4f7ff',
    secondaryVariant: '#e6edff',
    secondaryVariant2: '#ebf0ff',
    background: '#f9f9f9',
    alert: '#e54b4b',
    black1: '#383838',
    black2: '#333333',
    gray1: '#cccccc',
    gray2: '#c7c7c7',
    gray3: '#eeeeee',
    gray4: '#b7b7b7',
    gray5: '#aaaaaa',
    gray6: '#6a6a6a',
    gray7: '#dddddd',
    gray8: '#999999',
    gray9: '#f5f5f5',
    gray10: '#f4f4f4',
    lightblue1: '#9eb5f4',
    emphasis: '#3e9fff',
    subText: '#555555',
    notification: '#f467ad',
    request: '#7293ff',
    secure: '#f4fce5',
    insecure: '#fff4f4',
    success: '#8ec21f',
    white: 'white',

    // web
    loginBackground: '#cddaf8',
    loginHighlight: '#2687cc',
    textColorInPrimary: '#889dd6',
    layoutBackground: '#f4f6fb',
    inactiveIcon: '#c3ceea',
    progressBarInactive: '#f4f6fb',
  },
};
