import { AxiosError, AxiosResponse } from 'axios';
import { useCallback, useContext, useState } from 'react';
import { QueryKey, useMutation, useQuery, useQueryClient } from 'react-query';

import { OAuthContext } from '../../web/src/OAuthProvider';
import { ErrorResponse } from '../api/hscan';
import { KeycloakErrorResponseDetail } from '../api/hscan/account';
import {
  AccountInformation,
  CheckUsernameResponse,
  IssueResetPasswordWithEmailRequest,
  ResetPasswordRequest,
  ResetPasswordWithPhoneRequest,
  SignInRequest,
  SignUpRequest,
  SignUpType,
  issueResetPasswordWithEmail,
  requestAccountInformation,
  requestCheckUsername,
  requestFormAction,
  requestSignOut,
  resetPasswordWithPhone,
} from '../api/oauth/oauth';
import { HGATE_INCLUDED } from '../env';
import { StorageContext, StorageKey } from '../storageKey';
import { SignInRedirectRequest, getSignInUrl } from '../utils/oauth';

import { MutationOption, QueryOption } from './react-query-type';

export const useSignIn = () => {
  const signIn = useCallback(async (req: SignInRequest) => {
    await requestFormAction(req);
  }, []);
  return useMutation(signIn);
};

export const useSignInRedirect = () => {
  const { onSilentLogin } = useContext(OAuthContext);

  const signInRedirect = useCallback(
    async (req: SignInRedirectRequest, openNewWindow = false) => {
      if (onSilentLogin) {
        return;
      }
      if (!HGATE_INCLUDED) {
        if (req.register && req.signUpType === undefined) {
          req.signUpType = SignUpType.EMAIL;
        }
        if (!req.register && req.signInType === undefined) {
          req.signInType = 'email';
        }
      }
      const url = getSignInUrl(req);
      if (openNewWindow) {
        return window.open(url);
      } else {
        location.href = url;
      }
    },
    [onSilentLogin],
  );
  return signInRedirect;
};

export const useSignOut = (options?: MutationOption<void, void>) => {
  const queryClient = useQueryClient();
  const storage = useContext(StorageContext);
  const signOut = useCallback(async () => {
    await requestSignOut();
    storage.removeItem(StorageKey.USER);
    queryClient.invalidateQueries('autoSignIn');
  }, [queryClient, storage]);
  return useMutation(signOut, options);
};

export const useSignUp = () => {
  const signUp = useCallback(async (req: SignUpRequest) => {
    await requestFormAction(req);
  }, []);
  return useMutation(signUp);
};

export const useResetPassword = () => {
  const resetPassword = useCallback(async (req: ResetPasswordRequest) => {
    await requestFormAction(req);
  }, []);
  return useMutation(resetPassword);
};

export const getKeycloakBaseUrl = (realmName: string) => {
  const baseUrl = window.location.origin;
  return `${baseUrl}/auth/realms/${realmName}`;
};

export const useAccountInformation = (url: string, impUid: string) => {
  const fetch = () => requestAccountInformation(url, { impUid });
  return useQuery<
    AxiosResponse<AccountInformation>,
    AxiosError<KeycloakErrorResponseDetail>
  >(['account', impUid], fetch, { enabled: !!impUid.length });
};

export const useResetPasswordWithPhone = (
  url: string,
  options?: MutationOption<
    ResetPasswordWithPhoneRequest,
    unknown,
    AxiosError<ErrorResponse>
  >,
) => {
  return useMutation(request => resetPasswordWithPhone(url, request), options);
};

export const useIssueResetPasswordWithEmail = (
  url: string,
  options?: MutationOption<IssueResetPasswordWithEmailRequest, unknown>,
) => {
  return useMutation(
    request => issueResetPasswordWithEmail(url, request),
    options,
  );
};

export interface CheckUserRequest {
  username: string;
  phone?: string;
  email?: string;
}
export const useCheckUser = (
  url: string,
  request: CheckUserRequest,
  options?: QueryOption<CheckUsernameResponse>,
) => {
  const { username, phone, email } = request;
  const fetch = useCallback(async () => {
    const { data } = await requestCheckUsername(url, username, phone, email);
    return data;
  }, [url, username, phone, email]);
  return useQuery(
    ['requestCheckUsername', username] as QueryKey,
    fetch,
    options,
  );
};

export const useRememberUsername = () => {
  const storage = useContext(StorageContext);
  const savedUsername = localStorage.getItem(StorageKey.REMEMBER_USERNAME);
  const [doRemember, setDoRemember] = useState(savedUsername !== null);
  const saveUsername = useCallback(
    (username: string) => {
      if (doRemember) {
        storage.setItem(StorageKey.REMEMBER_USERNAME, username);
      } else {
        storage.removeItem(StorageKey.REMEMBER_USERNAME);
      }
    },
    [storage, doRemember],
  );

  return { doRemember, setDoRemember, savedUsername, saveUsername };
};
