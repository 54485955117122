import React from 'react';

interface SpacerProps {
  width?: string | number;
  height?: string | number;
}
export const Spacer = (props: SpacerProps) => {
  const { width = '100%', height = '100%' } = props;

  return (
    <div
      style={{
        display: 'inline-block',
        flexShrink: 0,
        width,
        height,
      }}
    />
  );
};
