import { useState, useEffect, createContext } from 'react';

export enum WindowSize {
  NORMAL = 'NORMAL',
  SHRINK_MENU = 'SHRINK_MENU', // < 1130
  MOBILE = 'MOBILE', // < 650 // 로그아웃 상태의 상단바 메뉴 글자 wrap 되기 직전
}
export const WindowSizeContext = createContext<WindowSize>(WindowSize.NORMAL);
export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<WindowSize>(WindowSize.NORMAL);
  useEffect(() => {
    function onResizeWindow() {
      const size = document.body.clientWidth;
      if (size < 650) {
        setWindowSize(WindowSize.MOBILE);
      } else if (size < 1130) {
        setWindowSize(WindowSize.SHRINK_MENU);
      } else {
        setWindowSize(WindowSize.NORMAL);
      }
    }
    onResizeWindow();
    window.addEventListener('resize', onResizeWindow);

    return () => {
      window.removeEventListener('resize', onResizeWindow);
    };
  }, []);

  return windowSize;
};
