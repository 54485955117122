import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';

import { HSCAN_FRONT_URL } from '../../../../shared/env';
import { StorageContext } from '../../../../shared/storageKey';
import { ExternalPageContext } from '../../features/Layout/ExternalPageContext';
import { useWindowSize, WindowSizeContext } from '../../hooks/windowSizeHook';

import { KeycloakLayout } from './features/Layout/KeycloakLayout';
import { KeycloakLayoutHeaderLinks } from './features/Layout/KeycloakLayoutHeaderLinks';
import { KeycloakMenu } from './features/Layout/MenuDrawer';
import type { KcContext } from './kcContext';

const Login = lazy(() => import('./features/Login'));
const SignUp = lazy(() => import('./features/SignUp'));
const VerifyEmail = lazy(() => import('./features/VerifyEmail'));
const ResetPassword = lazy(() => import('./features/ResetPassword'));
const Info = lazy(() => import('./features/Info'));

export const DEBUG = !!process.env.REACT_APP_DEBUG_KEYCLOAK;

const Redirect = () => {
  const location = useLocation();
  if (!HSCAN_FRONT_URL.includes(window.location.host)) {
    window.location.replace(`${HSCAN_FRONT_URL}${location.pathname}`);
  }
  return null;
};

export default function KcApp(props: { kcContext: KcContext }) {
  const { kcContext } = props;
  const windowSize = useWindowSize();

  const baseUrl =
    (kcContext as KcContext & { client: { baseUrl?: string } }).client
      .baseUrl ?? '';

  return (
    <Suspense>
      <WindowSizeContext.Provider value={windowSize}>
        <StorageContext.Provider
          value={{
            removeItem: window.localStorage.removeItem.bind(localStorage),
            clear: window.localStorage.clear.bind(localStorage),
            setItem: window.localStorage.setItem.bind(localStorage),
            getItem: key => Promise.resolve(localStorage.getItem(key)),
          }}>
          <BrowserRouter>
            <Routes>
              <Route
                element={
                  <ExternalPageContext.Provider
                    value={{
                      external: true,
                      baseUrl: baseUrl,
                    }}>
                    <KeycloakLayout
                      links={
                        <KeycloakLayoutHeaderLinks kcContext={kcContext} />
                      }
                      menus={<KeycloakMenu kcContext={kcContext} />}
                    />
                  </ExternalPageContext.Provider>
                }>
                <Route
                  path="/auth/*"
                  element={(() => {
                    switch (kcContext.pageId) {
                      case 'login.ftl':
                        return <Login kcContext={kcContext} />;
                      case 'register.ftl':
                        return <SignUp kcContext={kcContext} />;
                      case 'login-verify-email.ftl':
                        return <VerifyEmail kcContext={kcContext} />;
                      case 'login-update-password.ftl':
                        return <ResetPassword kcContext={kcContext} />;
                      case 'info.ftl':
                        return <Info kcContext={kcContext} />;
                    }
                  })()}
                />
              </Route>
              <Route path="/*" element={<Redirect />} />
            </Routes>
          </BrowserRouter>
        </StorageContext.Provider>
      </WindowSizeContext.Provider>
    </Suspense>
  );
}
