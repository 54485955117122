import { AxiosError } from 'axios';
import { useCallback, useContext, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { ErrorResponse } from '../api/hscan';
import {
  ChangePasswordBody,
  KeycloakErrorResponseDetail,
  changeName,
  changePassword,
  changePhoneNumber,
  unregister,
  changeLocale,
  Locale,
  changeGender,
  changeBirthDate,
  Gender,
} from '../api/hscan/account';
import { Person, requestUserInfo } from '../api/hscan/demographics';
import { requestCheckSession } from '../api/oauth/oauth';
import { SetUserContext, UserContext } from '../context/UserContext';
import { StorageKey, StorageContext } from '../storageKey';

import { useSignOut } from './oauthHook';
import { MutationOption } from './react-query-type';

export const useUserState = (): [Person | undefined] => {
  const user = useContext(UserContext);
  return useMemo(() => [user], [user]);
};

export const useRefreshUserInfo = () => {
  const queryClient = useQueryClient();
  const storage = useContext(StorageContext);
  const [user] = useUserState();
  return useCallback(async () => {
    if (user) {
      const newUser = await requestUserInfo();
      storage.setItem(StorageKey.USER, JSON.stringify(newUser.data));
      queryClient.invalidateQueries('autoSignIn');
    }
  }, [user, storage, queryClient]);
};

export const useAutoSignIn = () => {
  const setUser = useContext(SetUserContext);
  const storage = useContext(StorageContext);

  const autoSignIn = useCallback(async () => {
    try {
      await requestCheckSession();
      const newUser = await requestUserInfo();
      storage.setItem(StorageKey.USER, JSON.stringify(newUser.data));
      setUser(newUser.data);
      return newUser.data;
    } catch {
      storage.removeItem(StorageKey.USER);
      setUser(undefined);
      return undefined;
    }
  }, [setUser, storage]);

  return useQuery('autoSignIn', autoSignIn);
};

export const useUnregister = (options?: MutationOption<string, void>) => {
  const { mutate: signOut } = useSignOut();

  const fetch = useCallback(
    async (password: string) => {
      await unregister(password);
      signOut();
    },
    [signOut],
  );
  return useMutation(fetch, options);
};

export const useChangePassword = (
  options?: MutationOption<
    ChangePasswordBody,
    void,
    AxiosError<ErrorResponse<KeycloakErrorResponseDetail>>
  >,
) => {
  const [user] = useUserState();
  const reset = useCallback(
    async (params: ChangePasswordBody) => {
      if (user) {
        await changePassword(params);
      } else {
        // authentication failed
        throw new Error();
      }
    },
    [user],
  );
  return useMutation(reset, options);
};

export const useChangePhoneNumber = (
  options?: MutationOption<
    string,
    void,
    AxiosError<ErrorResponse<KeycloakErrorResponseDetail>>
  >,
) => {
  const fetch = useCallback(async (impUid: string) => {
    await changePhoneNumber({ impUid });
  }, []);
  return useMutation(fetch, options);
};

export const useNotVerifiedChangePhoneNumber = (
  options?: MutationOption<
    string,
    void,
    AxiosError<ErrorResponse<KeycloakErrorResponseDetail>>
  >,
) => {
  const fetch = useCallback(async (phone: string) => {
    await changePhoneNumber({ phone });
  }, []);
  return useMutation(fetch, options);
};

export const useChangeName = (
  options?: MutationOption<
    string,
    void,
    AxiosError<ErrorResponse<KeycloakErrorResponseDetail>>
  >,
) => {
  const fetch = useCallback(async (impUid: string) => {
    await changeName({ impUid });
  }, []);
  return useMutation(fetch, options);
};

export const useNotVerifiedChangeName = (
  options?: MutationOption<
    string,
    void,
    AxiosError<ErrorResponse<KeycloakErrorResponseDetail>>
  >,
) => {
  const fetch = useCallback(async (name: string) => {
    await changeName({ name });
  }, []);
  return useMutation(fetch, options);
};

export const useChangeLocale = (
  options?: MutationOption<
    string,
    void,
    AxiosError<ErrorResponse<KeycloakErrorResponseDetail>>
  >,
) => {
  const fetch = useCallback(async (locale: Locale) => {
    await changeLocale({ locale });
  }, []);
  return useMutation(fetch, options);
};

export const useChangeGender = (
  options?: MutationOption<
    string,
    void,
    AxiosError<ErrorResponse<KeycloakErrorResponseDetail>>
  >,
) => {
  const fetch = useCallback(async (gender: Gender) => {
    await changeGender({ gender });
  }, []);
  return useMutation(fetch, options);
};

export const useChangeBirthDate = (
  options?: MutationOption<
    string,
    void,
    AxiosError<ErrorResponse<KeycloakErrorResponseDetail>>
  >,
) => {
  const fetch = useCallback(async (birthDate: string) => {
    await changeBirthDate({ birthDate });
  }, []);
  return useMutation(fetch, options);
};
