import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {
  Collapse,
  List as MuiList,
  ListItemButton as MuiListItem,
  ListItemIcon,
  ListItemText as MuiListItemText,
  SwipeableDrawer,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';

import { BRAND_NAME, HGATE_INCLUDED } from '../../../../shared/env';
import { useUserState } from '../../../../shared/hooks/accountHook';
import {
  useSignInRedirect,
  useSignOut,
} from '../../../../shared/hooks/oauthHook';
import closeIcon from '../../assets/icons/icon_close.svg';
import headphoneIcon from '../../assets/icons/icon_headphone.svg';
import homeIcon from '../../assets/icons/icon_home.svg';
import infoIcon from '../../assets/icons/icon_info.svg';
import peopleIcon from '../../assets/icons/icon_people.svg';
import uploadCloudIcon from '../../assets/icons/icon_upload_cloud.svg';
import xrayDownloadIconBlue from '../../assets/icons/xray_download_blue_thick.png';
import xrayUploadIconBlue from '../../assets/icons/xray_upload_blue_thick.png';
import xrayViewIconBlue from '../../assets/icons/xray_view_blue_thick.png';
import { BlueRoundedButton } from '../../common/components/BlueRoundedButton';
import { ColoredIcon } from '../../common/components/Icon';
import { Spacer } from '../../common/components/Spacer';

import { LayoutHeaderLink } from './components';

const MenuContainer = styled.div`
  height: 100%;
  overflow: auto;
  width: 256px;
  background-color: #00197e;
  position: relative;
`;

const CloseButton = styled.div`
  position: absolute;
  right: 28px;
  top: 19px;
  cursor: pointer;
`;

const AccountActionContainer = styled.div`
  margin-top: 80px;
  margin-bottom: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const List = styled(MuiList)`
  height: calc(100% - 157px);
  overflow: auto;
`;

// MUI list item button이 border를 없애버려서 !important로 강제해야 border가 생김
const ListItem = styled(MuiListItem)`
  border-bottom: 0.5px solid #666973 !important;
`;

const ListItemText = styled(MuiListItemText)<{ $nested?: boolean }>`
  color: white;
  font-weight: bold;
  font-size: 16px;
  ${p => (p.$nested ? 'padding-left: 56px' : '')}
`;

interface MobileMenuProps {
  onClose?: () => void;
  children?: React.ReactNode;
}
export const MobileMenu = (props: MobileMenuProps) => {
  const { onClose, children } = props;
  return (
    <MenuContainer>
      <CloseButton onClick={onClose}>
        <ColoredIcon
          width="24px"
          height="24px"
          imageUrl={closeIcon}
          imageWidth="24px"
          color="white"
        />
      </CloseButton>
      {children}
    </MenuContainer>
  );
};

interface MenuProps {
  onNavigate?: () => void;
}

export const LoginMenu = (props: MenuProps) => {
  const { onNavigate } = props;
  const { t } = useTranslation('translation', { keyPrefix: 'layout' });
  const signInRedirect = useSignInRedirect();
  const _navigate = useNavigate();
  const navigate = (path: string) => {
    _navigate(path);
    onNavigate?.();
  };
  return (
    <>
      <AccountActionContainer>
        <LayoutHeaderLink onClick={() => signInRedirect({})}>{`${t(
          'headers.links.loginButton',
        )}`}</LayoutHeaderLink>
        <Spacer width={24} />
        <BlueRoundedButton
          maxWidth="100px"
          onClick={() => signInRedirect({ register: true })}
          content={`${t('headers.links.signupButton')}`}
        />
      </AccountActionContainer>
      <List disablePadding>
        <ListItem onClick={() => navigate('/images-list')}>
          <ListItemText
            disableTypography
            primary={`${t('headers.links.serviceLink')}`}
          />
        </ListItem>
        <ListItem onClick={() => navigate('/about')}>
          <ListItemText
            disableTypography
            primary={`${t('headers.links.aboutLink', {
              brandName: BRAND_NAME,
            })}`}
          />
        </ListItem>
        <ListItem onClick={() => ChannelIO('showMessenger')}>
          <ListItemText
            disableTypography
            primary={`${t('headers.links.centerLink')}`}
          />
        </ListItem>
      </List>
    </>
  );
};

enum Submenu {
  NONE,
  DOWNLOAD,
  MYPAGE,
}

export const LogoutMenu = (props: MenuProps) => {
  const { onNavigate } = props;
  const { t } = useTranslation('translation', { keyPrefix: 'layout' });
  const _navigate = useNavigate();
  const navigate = (path: string) => {
    _navigate(path);
    onNavigate?.();
  };

  const { mutate: signOut } = useSignOut();
  const [user] = useUserState();

  const onClickLogoutButton = () => {
    signOut(undefined);
    navigate('/');
  };

  const [submenu, setSubmenu] = useState(Submenu.NONE);

  if (!user) {
    return null;
  }
  return (
    <>
      <AccountActionContainer>
        <LayoutHeaderLink>
          {t('sidebar.name', { name: user.name })}
        </LayoutHeaderLink>
        <Spacer width={16} />
        <BlueRoundedButton
          maxWidth="100px"
          onClick={onClickLogoutButton}
          content={`${t('sidebar.logoutButton')}`}
        />
      </AccountActionContainer>
      <List disablePadding>
        <ListItem onClick={() => navigate('/')}>
          <ListItemIcon>
            <ColoredIcon
              width="24px"
              height="24px"
              imageUrl={homeIcon}
              imageWidth="17px"
              color="#0051ff"
            />
          </ListItemIcon>
          <ListItemText disableTypography primary={`${t('sidebar.tab.0')}`} />
        </ListItem>
        <ListItem onClick={() => navigate('/images-list')}>
          <ListItemIcon>
            <ColoredIcon
              width="24px"
              height="24px"
              imageUrl={xrayViewIconBlue}
              imageWidth="24px"
              color="#0051ff"
            />
          </ListItemIcon>
          <ListItemText disableTypography primary={`${t('sidebar.tab.1')}`} />
        </ListItem>
        {HGATE_INCLUDED && user?.identified && (
          <ListItem onClick={() => navigate('/request-images')}>
            <ListItemIcon>
              <ColoredIcon
                width="24px"
                height="24px"
                imageUrl={xrayDownloadIconBlue}
                imageWidth="24px"
                color="#0051ff"
              />
            </ListItemIcon>
            <ListItemText disableTypography primary={`${t('sidebar.tab.2')}`} />
          </ListItem>
        )}
        <ListItem onClick={() => navigate('/send-images')}>
          <ListItemIcon>
            <ColoredIcon
              width="24px"
              height="24px"
              imageUrl={xrayUploadIconBlue}
              imageWidth="24px"
              color="#0051ff"
            />
          </ListItemIcon>
          <ListItemText disableTypography primary={`${t('sidebar.tab.3')}`} />
        </ListItem>
        <ListItem onClick={() => navigate('/upload-images')}>
          <ListItemIcon>
            <ColoredIcon
              width="24px"
              height="24px"
              imageUrl={uploadCloudIcon}
              imageWidth="22px"
              color="#0051ff"
            />
          </ListItemIcon>
          <ListItemText disableTypography primary={`${t('sidebar.tab.4')}`} />
        </ListItem>
        <ListItem
          onClick={() =>
            setSubmenu(prev =>
              prev === Submenu.MYPAGE ? Submenu.NONE : Submenu.MYPAGE,
            )
          }>
          <ListItemIcon>
            <ColoredIcon
              width="24px"
              height="24px"
              imageUrl={peopleIcon}
              imageWidth="16px"
              color="#0051ff"
            />
          </ListItemIcon>
          <ListItemText disableTypography primary={`${t('sidebar.tab.5')}`} />
          {submenu === Submenu.MYPAGE ? (
            <ArrowDropUpIcon htmlColor="#0051ff" />
          ) : (
            <ArrowDropDownIcon htmlColor="#0051ff" />
          )}
        </ListItem>
        <Collapse in={submenu === Submenu.MYPAGE} unmountOnExit>
          <MuiList disablePadding>
            <ListItem onClick={() => navigate('/my-page')}>
              <ListItemText
                $nested
                disableTypography
                primary={`${t('sidebar.tab.6')}`}
              />
            </ListItem>
            <ListItem onClick={() => navigate('/notifications')}>
              <ListItemText
                $nested
                disableTypography
                primary={`${t('sidebar.tab.7')}`}
              />
            </ListItem>
            {user?.identified && (
              <ListItem onClick={() => navigate('/requested-images')}>
                <ListItemText
                  $nested
                  disableTypography
                  primary={`${t('sidebar.tab.8')}`}
                />
              </ListItem>
            )}
            {user?.identified && (
              <ListItem onClick={() => navigate('/uploaded-images')}>
                <ListItemText
                  $nested
                  disableTypography
                  primary={`${t('sidebar.tab.9')}`}
                />
              </ListItem>
            )}
            <ListItem onClick={() => navigate('/agreement')}>
              <ListItemText
                $nested
                disableTypography
                primary={`${t('sidebar.tab.10')}`}
              />
            </ListItem>
          </MuiList>
        </Collapse>
        <ListItem
          onClick={() => {
            onNavigate?.();
            ChannelIO('showMessenger');
          }}>
          <ListItemIcon>
            <ColoredIcon
              width="24px"
              height="24px"
              imageUrl={headphoneIcon}
              imageWidth="18px"
              color="#0051ff"
            />
          </ListItemIcon>
          <ListItemText disableTypography primary={`${t('sidebar.tab.11')}`} />
        </ListItem>
        <ListItem
          onClick={() =>
            window.open(
              'https://drive.google.com/drive/u/0/folders/1I93q2NeUu_SXpUbdGTZCwy6kNWHbC7AL',
              '_blank',
            )
          }>
          <ListItemIcon>
            <ColoredIcon
              width="24px"
              height="24px"
              imageUrl={infoIcon}
              imageWidth="18px"
              color="#0051ff"
            />
          </ListItemIcon>
          <ListItemText disableTypography primary={`${t('sidebar.tab.12')}`} />
        </ListItem>
      </List>
    </>
  );
};

interface MenuDrawerProps {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  modalDrawer?: boolean;
  isNonMemberMenu?: boolean;
}
export const MenuDrawer = (props: MenuDrawerProps) => {
  const {
    open,
    onClose,
    onOpen,
    modalDrawer = false,
    isNonMemberMenu = false,
  } = props;
  const [user] = useUserState();

  // set modal zIndex < modal drawer zIndex < snackbar zIndex
  // https://mui.com/material-ui/customization/z-index/
  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      sx={{ zIndex: modalDrawer ? 1350 : 1200 }}>
      <MobileMenu onClose={onClose}>
        {user && !isNonMemberMenu ? (
          <LogoutMenu onNavigate={onClose} />
        ) : (
          <LoginMenu onNavigate={onClose} />
        )}
      </MobileMenu>
    </SwipeableDrawer>
  );
};
