import axios from 'axios';

import { HSCAN_BACKEND_DEMOGRAPHICS_URL } from '../../env';

export interface SignInRequest {
  username: string;
  password: string;
  deviceToken?: string;
}

export interface KeycloakErrorResponseDetail {
  error: string;
  error_description: string;
}

export enum Gender {
  O = 'O',
  F = 'F',
  M = 'M',
}

export enum Locale {
  ko = 'ko',
  en = 'en',
  es = 'es',
  id = 'id',
}

export const unregister = (password: string) => {
  return axios.delete(`${HSCAN_BACKEND_DEMOGRAPHICS_URL}/user`, {
    headers: { password },
  });
};

export interface ChangePasswordBody {
  oldPassword: string;
  newPassword: string;
}
export const changePassword = (body: ChangePasswordBody) => {
  return axios.put(`${HSCAN_BACKEND_DEMOGRAPHICS_URL}/user/password`, body);
};

export interface ChangePhoneNumberParams {
  phone?: string;
  impUid?: string;
}
export const changePhoneNumber = (params: ChangePhoneNumberParams) => {
  return axios.put(
    `${HSCAN_BACKEND_DEMOGRAPHICS_URL}/user/phone`,
    new URLSearchParams({ ...params }),
  );
};

export interface ChangeNameParams {
  impUid?: string;
  name?: string;
}
export const changeName = (params: ChangeNameParams) => {
  return axios.put(
    `${HSCAN_BACKEND_DEMOGRAPHICS_URL}/user/name`,
    new URLSearchParams({ ...params }),
  );
};

export interface ChangeLocaleParams {
  locale: Locale;
}
export const changeLocale = (params: ChangeLocaleParams) => {
  return axios.put(
    `${HSCAN_BACKEND_DEMOGRAPHICS_URL}/user/locale`,
    new URLSearchParams({ ...params }),
  );
};

export interface ChangeGenderParams {
  gender: Gender;
}
export const changeGender = (params: ChangeGenderParams) => {
  return axios.put(
    `${HSCAN_BACKEND_DEMOGRAPHICS_URL}/user/gender`,
    new URLSearchParams({ ...params }),
  );
};

export interface ChangeBirthDateParams {
  birthDate: string;
}
export const changeBirthDate = (params: ChangeBirthDateParams) => {
  return axios.put(
    `${HSCAN_BACKEND_DEMOGRAPHICS_URL}/user/birth`,
    new URLSearchParams({ ...params }),
  );
};
