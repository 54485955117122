import i18n from 'i18next';

import { BRAND_NAME } from '../../../shared/env';
import {
  englishWebTranslation,
  indonesianWebTranslation,
  koreanWebTranslation,
  spanishWebTranslation,
} from '../../../shared/translations';

type AppTitle = { common: { appTitle: string } };
const koreanAppTitle = (koreanWebTranslation.translation as AppTitle).common
  .appTitle;
const englishAppTitle = (englishWebTranslation.translation as AppTitle).common
  .appTitle;
const spanishAppTitle = (spanishWebTranslation.translation as AppTitle).common
  .appTitle;
const indonesianAppTitle = (indonesianWebTranslation.translation as AppTitle)
  .common.appTitle;

export function onChangeLocale(locale: 'ko' | 'en' | 'es' | 'id') {
  i18n.changeLanguage(locale);

  ChannelIO('updateUser', { language: locale });

  const appTitle =
    locale === 'ko'
      ? koreanAppTitle
      : locale === 'es'
      ? spanishAppTitle
      : locale === 'id'
      ? indonesianAppTitle
      : englishAppTitle;

  document.title = i18n.t(appTitle, { brandName: BRAND_NAME });
}
