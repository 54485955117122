import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';

import {
  LayoutHeaderLink,
  LayoutHeaderButton,
} from '../../../../features/Layout/components';
import { useRegisterUrl } from '../../hooks';
import { KcContext } from '../../kcContext';

export const NoUnderlineLink = styled.a`
  text-decoration: none;
`;

interface LoginButtonProps {
  kcContext: KcContext;
}
export const LoginButton = ({ kcContext }: LoginButtonProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'layout' });

  return (
    <NoUnderlineLink href={kcContext.url.loginUrl ?? '/'}>
      <LayoutHeaderLink>{t('headers.links.loginButton')}</LayoutHeaderLink>
    </NoUnderlineLink>
  );
};

interface SignUpButtonProps {
  kcContext: Extract<KcContext, { pageId: 'login.ftl' }>;
}
export const SignUpButton = ({ kcContext }: SignUpButtonProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'layout' });

  const registerUrl = useRegisterUrl(kcContext, location.origin);
  const handleClickSignUp = () => {
    location.href = registerUrl;
  };

  return (
    <LayoutHeaderButton onClick={handleClickSignUp}>
      {t('headers.links.signupButton')}
    </LayoutHeaderButton>
  );
};
