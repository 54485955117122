import { useMemo, useState } from 'react';

import { KcContext } from './kcContext';

export const useRegisterUrl = (
  kcContext: Extract<KcContext, { pageId: 'login.ftl' }>,
  origin?: string,
) =>
  useMemo(() => {
    const url = new URL(kcContext.url.registrationUrl, origin);

    const type = kcContext.signInType;
    url.searchParams.set(
      'signup_type',
      type === 'username' ? 'phone' : 'email',
    );
    return url.href;
  }, [kcContext, origin]);

export const useInitialFormStates = <States extends object>(
  kcContext: Extract<KcContext, { pageId: 'register.ftl' }>,
  defaultValues: States,
) => {
  const getInitialStates = () => {
    const get = <T>(key: string, defaultValue: T) => {
      return (
        ((kcContext.preset?.[key] ??
          kcContext.register.formData?.[key]) as T) ?? defaultValue
      );
    };

    const keys = Object.keys(defaultValues) as (keyof States)[];
    return keys.reduce(
      (prev, key) => ({
        ...prev,
        [key]: get(key as string, defaultValues[key]),
      }),
      defaultValues,
    );
  };

  return useState<States>(getInitialStates);
};
