import { LOCAL_STORAGE_LOCALE_KEY } from '../../web/src/common/components/LanguageSelector';
import { SignInType, SignUpType } from '../api/oauth/oauth';
import { LOGIN } from '../api/oauth/path';
import { HSCAN_BACKEND_URL } from '../env';

export interface SignInRedirectRequest {
  redirect?: string;
  register?: boolean;
  signUpType?: SignUpType;
  signInType?: SignInType;
  preset?: string;
  prompt?: string;
}
export const getSignInUrl = (req: SignInRedirectRequest) => {
  const { redirect, register, signUpType, signInType, preset, prompt } = req;
  const redirectUrl = new URL(`${HSCAN_BACKEND_URL}${LOGIN}`);
  if (redirect) {
    redirectUrl.searchParams.set('redirect_uri', redirect);
  }
  if (register) {
    redirectUrl.searchParams.set('register', 'true');
  }
  if (signUpType) {
    redirectUrl.searchParams.set('signup_type', signUpType);
  }
  if (signInType) {
    redirectUrl.searchParams.set('signin_type', signInType);
  }
  if (preset) {
    redirectUrl.searchParams.set('preset', preset);
  }
  if (prompt) {
    redirectUrl.searchParams.set('prompt', prompt);
  }
  const localeInStorage = localStorage.getItem(LOCAL_STORAGE_LOCALE_KEY);
  if (localeInStorage) {
    redirectUrl.searchParams.set('ui_locales', localeInStorage);
  }
  return redirectUrl.href;
};
