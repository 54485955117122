import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { USE_THETRARIS_LOGO } from '../../../../shared/env';
import logoIcon from '../../assets/icons/icon_logo.png';
import logoIconEn from '../../assets/icons/icon_logo_en.png';
import mobileLogoIcon from '../../assets/icons/icon_mobile-logo.png';
import thetrarisLogoIcon from '../../assets/icons/icon_thetraris_logo.png';
import { ExternalPageContext } from '../../features/Layout/ExternalPageContext';
import { WindowSize, WindowSizeContext } from '../../hooks/windowSizeHook';

import { ColoredIcon } from './Icon';

const NoUnderlineLink = styled(Link)`
  text-decoration: none;
`;
const NoUnderlineAnchor = styled.a`
  text-decoration: none;
`;

const HScanLogo = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const logo = language === 'ko' ? logoIcon : logoIconEn;
  const isShrink = WindowSize.NORMAL !== useContext(WindowSizeContext);

  return (
    <ColoredIcon
      width={isShrink ? '92px' : '280px'}
      height={isShrink ? '24px' : '32px'}
      imageUrl={isShrink ? mobileLogoIcon : logo}
      imageWidth={isShrink ? '92px' : '280px'}
      color="#ffffff"
    />
  );
};

const ThetrarisLogo = () => {
  const isShrink = WindowSize.NORMAL !== useContext(WindowSizeContext);
  return (
    <ColoredIcon
      width={isShrink ? '45px' : '70px'}
      height={isShrink ? '45px' : '70px'}
      imageUrl={thetrarisLogoIcon}
      imageWidth={isShrink ? '45px' : '70px'}
      color="#ffffff"
    />
  );
};

export const LogoTitle = () => {
  const { external, baseUrl } = useContext(ExternalPageContext);

  return external ? (
    <NoUnderlineAnchor href={baseUrl}>
      {USE_THETRARIS_LOGO ? <ThetrarisLogo /> : <HScanLogo />}
    </NoUnderlineAnchor>
  ) : (
    <NoUnderlineLink to={'/'}>
      {USE_THETRARIS_LOGO ? <ThetrarisLogo /> : <HScanLogo />}
    </NoUnderlineLink>
  );
};
